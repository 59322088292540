import { inject, Injectable } from '@angular/core';
import { environment as Config } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { RolesDTO, WebOperationDTO } from '../role.model';
import { HttpClient } from '@angular/common/http';
import { OperatorDTOExtended } from '../../operators/operators.model';
import { RxjsUtils } from '../../../shared/utility/rxjs-utils';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private readonly http = inject(HttpClient);
  private readonly rxjsUtils = inject(RxjsUtils);
  private readonly baseUrlPath = `${Config.apiBaseUrl}/roles`;

  public getAllRoles (): Observable<RolesDTO[]> {
    return this.http.get<RolesDTO[]>(`${this.baseUrlPath}`)
      .pipe(this.rxjsUtils.simpleCatchErrorWithLog<RolesDTO[]>([]));
  }

  public getRoleById (roleId: number): Observable<RolesDTO | null> {
    return this.http.get<RolesDTO>(`${this.baseUrlPath}/${roleId}`)
      .pipe(this.rxjsUtils.simpleCatchErrorWithLog<RolesDTO | null>(null));
  }

  public getAllWebOperations (): Observable<WebOperationDTO[]> {
    return this.http.get<WebOperationDTO[]>(`${this.baseUrlPath}/weboperations`);
  }

  public createRole (role: Partial<RolesDTO>): Observable<RolesDTO> {
    return this.http.post<RolesDTO>(`${this.baseUrlPath}`, role);
  }

  public updateRole (role: RolesDTO): Observable<RolesDTO> {
    return this.http.put<RolesDTO>(`${this.baseUrlPath}/${role.id}`, role);
  }

  public deleteRole (roleId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrlPath}/${roleId}`);
  }

  /**
   * .
   * Serve per ottenere il path della pagina in base alla weboperation.
   *
   * @param id - Id della webOperation.
   * @param operator - Operatore su cui controllare le web operations disponibili.
   * @returns String.
   * @example
   */
  public getWebOperationPathById (id: number, operator: OperatorDTOExtended): string {
    let path = 'my-profile';
    if (id) {
      const rfwo = operator?.role?.webOperationList?.find(wo => wo.webOperationId === id);
      if (rfwo != null && rfwo.webOperationCode != null) {
        switch (rfwo.webOperationCode) {
          case 'Calendar':
            path = 'calendar';
            break;
          case 'Assets':
            path = 'asset';
            break;
          case 'Planner':
            path = 'planner';
            break;
          case 'Ticket':
            path = 'ticket';
            break;
          case 'Consultations':
            path = 'consultations';
            break;
          case 'Dashboard':
            path = 'dashboard';
            break;
          case 'IoT':
            path = 'monitoring';
            break;
          case 'Settings':
            path = 'settings';
            break;
          case 'Items':
            path = 'items';
            break;
          case 'NonCompliance':
            path = 'non-compliance';
            break;
        }
      }
    }
    return path;
  }
}
